@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap');

* {
    font-family: 'Roboto', sans-serif;
}

/* tr:nth-child(even) {
    background: #F8C8DC;	
} */



/*end*/
.accor {

    transition: 0.5s;
}

.body {
    max-height: 0;
    overflow: hidden;
    transition: 120ms ease-in;
}

.showdata {
    max-height: auto;
    transition: 120ms ease-out;
}

.scroll-md {
    -webkit-overflow-scrolling: touch;
}

/* custom scroll */
/* width */
.scroll-md::-webkit-scrollbar {
    width: 6px;

}

/* Track */
.scroll-md::-webkit-scrollbar-track {
    background: transparent;
    padding: 2px;
}

/* Handle */
.scroll-md::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #ffffff52;
}

/* Handle on hover */
.scroll-md::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* loading animation */
.sk-fading-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }
  
  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 5 auto;
    width: 20%;
    height: 20%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
            animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  .sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  .sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  .sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  .sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
            transform: rotate(150deg);
  }
  .sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
            transform: rotate(210deg);
  }
  .sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
            transform: rotate(240deg);
  }
  .sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
            transform: rotate(300deg); 
  }
  .sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
            transform: rotate(330deg); 
  }
  .sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; 
  }
  .sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; 
  }
  .sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; 
  }
  .sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; 
  }
  .sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; 
  }
  .sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s; 
  }
  .sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s; 
  }
  .sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }
  .sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
  }
  .sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
  }
  
  @-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }
  
  @keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; } 
  }

  .match_bg_blue_index-0{
    background-color:#72bbef;
  }
  .match_bg_blue_index-1{
    background-color:#92c9f0;
  }
  .match_bg_blue_index-2{
    background-color:#b1d6f0;
  }

  .match_bg_pink_index-0{
    background-color:#f5a9ba;
  }
  .match_bg_pink_index-1{
    background-color:#f7bbc8;
  }
  .match_bg_pink_index-2{
    background-color:#f5cdd5;
  }

  .pulse2 {
    -webkit-animation: pulse 400ms infinite alternate;
            animation: pulse 400ms infinite alternate;
  }
  @-webkit-keyframes pulse {
    0% { background-color: orange; }
    100% { background-color: greenyellow; }
  }
  @keyframes pulse {
    0% { background-color: orange; }
    100% { background-color: greenyellow; }
  }

  .pulse3 {
    -webkit-animation: pulse4 500ms infinite alternate;
            animation: pulse4 500ms infinite alternate;
  }
  @-webkit-keyframes pulse4 {
    0% { color: orangered; }
    50% { color: yellow; }
    100% { color: green; }
  }
  @keyframes pulse4 {
    0% { color: orangered; }
    50% { color: yellow; }
    100% { color: green; }
  }







/* Pagination container styles */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 20px; /* Adjust spacing as needed */
}

/* Pagination page item styles */
.page-cls {
  margin: 0 5px; /* Adjust margin between page items */
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  
}
/* Hide the spin buttons in Webkit browsers (Chrome, Safari, Edge) */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spin buttons in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}




/* Active page item styles */
.active {
  background-color: #3e44d5;
  color: #fff;
  border-color: #3e44d5;
}

/* Previous and Next button styles */
.pagination .previous,
.pagination .next {
  margin: 0 10px; /* Adjust margin around the buttons */
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

/* Styling for the ellipsis (break) item */
.break-me {
  margin: 0 5px; /* Adjust margin for the ellipsis */
  padding: 8px 12px;
  color: #555;
}




/* OtpTimerStyles.css */
.otp-timer {
  font-size: 20px;
  color: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-timer .timer {
  margin: 0 5px;
}

.otp-timer .resend-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.otp-timer .resend-button:hover {
  background-color: #45a049;
}



.pulse {

  box-shadow: 0 0 0 #F97941;
  animation: pulse 2s infinite;
}


.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(30, 255, 0, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(86, 255, 128, 1);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(117, 255, 129, 0.5);
    box-shadow: 0 0 0 0 rgba(63, 204, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgb(44, 255, 227, 0.2);
    box-shadow: 0 0 0 10px rgba(48, 255, 255, 0.2);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(30, 255, 79, 0.5);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}


/* src/index.css */

/* global-styles.css */

/* Style for custom scrollbar */
body {
  height: 100vh; /* Example height */
  overflow-y: auto; /* Enable vertical scrollbar */
}

/* WebKit (Chrome, Safari, Edge) */
body::-webkit-scrollbar {
  width: 8px; /* Width of vertical scrollbar */
}

body::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Track color */
}

body::-webkit-scrollbar-thumb {
  background-color: #F97941; /* Thumb color */
  border-radius: 10px; /* Round corners */
}

/* Optional: Hover effect for scrollbar thumb */
body::-webkit-scrollbar-thumb:hover {
  background-color: #3E44D5;
}

/* Firefox */
body {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #F97941 #f0f0f0; /* For Firefox */
}
